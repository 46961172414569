// eslint-disable-next-line
import { useEffect } from 'react'
import { navigate } from 'gatsby'

export default () => {
  useEffect(() => {
    navigate('/en-us/404/')
  }, [])
  return null
}
